import succes from "../Assets/icons/Success.png";
import logo from "../Assets/logo/Logo only.png";

const Success = ({ handleclose, text }) => {
  return (
    <div className="flex flex-col justify-center items-center fixed bg-black bg-opacity-35 h-screen w-screen right-0 top-0 bottom-0 z-50 ">
      <div className="relative bg-white p-3 flex flex-col justify-center items-center overflow-hidden w-96 rounded-lg">
        <img
          src={logo}
          className="absolute w-32 h-32 -top-6 -left-6 opacity-30"
          alt=""
        />
        <img src={succes} alt="" className="w-32 top-0" />
        <div className="text-center space-y-2">
          <h1 className="font-bold text-2xl text-Primary">Success! </h1>
          <p className="text-center text-black">{text}</p>
          <button
            onClick={handleclose}
            className="uppercase text-lg p-1 text-white w-full font-bold bg-Primary hover:bg-black duration-150 ease-in-out rounded-lg"
          >
            ok
          </button>
        </div>
      </div>
    </div>
  );
};
export default Success;
