import React, { useRef, useState } from "react";
import emailjs from "@emailjs/browser";
import Success from "../Success";
import Error from "../error";

const Emailform = () => {
  const [success, setSuccess] = useState(false);
  const [error, setError] = useState(false);
  const form = useRef();

  const sendEmail = (e) => {
    e.preventDefault();

    emailjs
      .sendForm("service_d5eicsb", "template_j6p6rxr", form.current, {
        publicKey: "9RG1zKcNB71uXSuWr",
      })
      .then(
        () => {
          console.log("SUCCESS!");
          setSuccess(true);
        },
        (error) => {
          console.log("FAILED...", error.text);
          setError(true)
        }
      );
  };
  return (
    <div className="flex">
      <form
        action=""
        ref={form}
        onSubmit={sendEmail}
        className="flex flex-col space-y-2 md:w-80 w-full"
      >
        <input
          type="email"
          name="from_name"
          id=""
          placeholder="Enter email address here"
          className="px-2 py-1 outline-none border border-Primary bg-black bg-opacity-50 text-white"
        />
        <textarea
          name="message"
          id=""
          cols="30"
          rows="4"
          placeholder="Write your message here"
          className="px-2 py-1 outline-none border border-Primary bg-black bg-opacity-50 text-white"
        />
        <button
          type="submit"
          value="send"
          className="bg-Primary hover:bg-black w-full text-white font-bold py-1"
        >
          SEND MESSAGE
        </button>
        {success && (
          <Success
            handleclose={() => {
              setSuccess(false);
            }}
            text={`Thank you for sending us and email.your email is have reached us.`}
          />
        )}
        {error && <Error handleClose={()=>{setError(false)}}/>}
      </form>
    </div>
  );
};

export default Emailform;
