import React, { useEffect, useState } from "react";
import { animateScroll as scroll } from "react-scroll";
import Navigation from "../Component/Navigation/Navigate";
import Phonenav from '../Component/Navigation/PhoneNavigate'
import Home from "../Pages/Home";
import Donation from "../Container/Donation/Donation";
import Card from "../Component/Mission & Vision/Card";
import Whatwedo from "../Container/Whatwearedoing/Whatwe";
import Education from "../Container/CancerEducation/Education";
import Newsfeed from "../Container/News Feed/Newsfeed";
import Testimonial from "../Container/Testimonial/Testimonial";
import MeetTeams from "../Container/Meet Teams/MeetTeams";
import Footer from "../Container/Footer/Footer";
import Partners from "../Container/Partners/Partners";
import useSticky from "../Usesticky";
import { useAuth } from "../Authentication";
import { IoIosArrowDropupCircle } from "react-icons/io";
import {jwtDecode} from 'jwt-decode'; // Import jwt-decode library
import TokenExchange from "../TokenCheck";

const ScrollRoute = () => {
  const [showGoUpButton, setShowGoUpButton] = useState(false);
  const isSticky = useSticky(500);
  const { authenticated, setAuthenticated } = useAuth();

  useEffect(() => {
    const handleScroll = () => {
      const scrollTop = window.pageYOffset || document.documentElement.scrollTop;
      const isOnHome = scrollTop === 0;
      const isNearBottom = scrollTop + window.innerHeight >= document.documentElement.scrollHeight - 10; // Adjust the threshold as needed
      setShowGoUpButton(!isOnHome && !isNearBottom);
    };

    window.addEventListener("scroll", handleScroll);
    return () => {
      window.removeEventListener("scroll", handleScroll);
    };
  }, []);

  const handleGoUpClick = () => {
    scroll.scrollToTop({ smooth: true, duration: 1000 }); // Adjust the duration for your desired speed
  };

  return (
    <div>
      <Navigation sticky={isSticky} authenticated={authenticated} />
      <Phonenav sticky={isSticky} authenticated={authenticated}/>
      {/* <TokenExchange/> */}
      <Home />
      <Donation />
      <Card />
      <Whatwedo />
      <Education />
      <Newsfeed />
      <Testimonial />
      <MeetTeams />
      <Partners/>
      <Footer />
      {showGoUpButton && (
        <button className="fixed bottom-4 md:right-10 right-4 text-Primary rounded z-10 duration-150 ease-in-out" onClick={handleGoUpClick}>
          <IoIosArrowDropupCircle className="md:text-6xl text-5xl" />
        </button>
      )}
    </div>
  );
};

export default ScrollRoute;
