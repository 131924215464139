import React from "react";
import { Link } from "react-router-dom";

const Cards = ({ link,Headline, News, image }) => {
  return (
    <Link to={link} target="_blank" className="flex flex-col md:justify-start justify-center md:items-start items-center text-start spacce-y-3 rounded-lg">
      <div className="">
        <img src={image} alt="" className="rounded-t-lg w-full aspect-video object-cover" />
      </div>
      <div className="bg-Secondary px-3 py-2 w-full h-48 z-50 rounded-b-lg ">
        <h1 className="font-semibold text-2xl">{Headline}</h1>
        <p className="text-gray-600 pt-2 pb-5">{News.slice(0, 150)}...</p>
      </div>
    </Link>
  );
};

export default Cards;
