import React from 'react'
import {
  Autoplay,
  Navigation,
  Pagination,
  Scrollbar,
  A11y,
} from 'swiper/modules'
import { Swiper, SwiperSlide } from 'swiper/react'
import 'swiper/css'
import 'swiper/css/navigation'
import 'swiper/css/pagination'
import 'swiper/css/scrollbar'
import { Element } from 'react-scroll'
import partner1 from '../../Assets/partners/Clinton_Health_Access_Initiative.png'
import partner2 from '../../Assets/partners/IMG_20230207_051205_398-compressed.jpg'
import partner3 from '../../Assets/partners/photo_2023-09-29_14-18-05.jpg'
import partner4 from '../../Assets/partners/photo_2024-03-08_07-39-46.jpg'

const Partners = () => {
  const partners = [
    { img: partner1},
    { img: partner2},
    { img: partner3},
    { img: partner4},
  ]

  return (
    <Element id="aboutus" className="md:px-10 px-4 w-full py-5 space-y-5">
      <h1 className="Header text-end">
        Our <span className="Title">Partners</span>
      </h1>
      <div className="flex justify-center w-full gap-32 items-center md:overflow-x-scroll py-3">
        <Swiper
          modules={[Autoplay, Navigation, Pagination, Scrollbar, A11y]}
          loop={true}
          speed={3000} // Set the speed of the animation in milliseconds
          autoplay={{
            delay: 0, // Set delay to 0 for continuous autoplay without pauses
            disableOnInteraction: false,
          }}
          breakpoints={{
            // Set different settings for different screen sizes
            640: {
              slidesPerView: 1,
            },
            768: {
              slidesPerView: 3,
            },
            1024: {
              slidesPerView: 4,
            },
          }}
        >
          {partners.map((items, index) => (
            <SwiperSlide key={index} className="">
              <div className="flex justify-center items-center pb-12">
                <img src={items.img} alt="" className="object-contain w-64 aspect-video" />
              </div>
            </SwiperSlide>
          ))}
        </Swiper>
      </div>
    </Element>
  )
}

export default Partners
