import React from 'react'
import img4 from '../../Assets/images/img4.jpg'
import Emailform from '../../Component/Email/Emailform'
import logo from '../../Assets/logo/Logo with text.png'
import Facebook from '../../Assets/icons/Facebook.png'
import Linkedin from '../../Assets/icons/Linkedin.png'
import Instagram from '../../Assets/icons/Instagram.png'
import Youtube from '../../Assets/icons/youtube.png'
import { Link } from 'react-scroll'
import { FaCopyright } from 'react-icons/fa6'

const Footer = () => {
  const social = [
    { icon: Facebook, links: 'Facebook',social:'https://www.facebook.com/profile.php?id=61554629918618',  info: 'donation'},
    { icon: Linkedin, links: 'Linkedin', social:'https://www.linkedin.com/company/hiwotfana-cancer-treatment-center/',   info: 'cancer education'},
    { icon: Instagram, links: 'Instagram',social:'https://www.instagram.com/octoberbreastcancersupport?igsh=NmN0Mzh6d3c3MTUx ', info: 'News feed'},
    { icon: Youtube, links: 'Youtube',social:'https://youtube.com/@HiwotFanaCancerTreatmentCenter?si=lG-nqIezTeEXohms',  },
  ]
  const nav = [
    { scroll: 'home', navigation: 'Home' },
    { scroll: 'whatwedo', navigation: 'What we do' },
    { scroll: 'cancereducation', navigation: 'Cancer education' },
    { scroll: 'newsfeed', navigation: 'News Feed' },
    { scroll: 'aboutus', navigation: 'About us' },
  ]
  return (
    <div className="text-white">
      <div className="absolute z-50 md:px-10 px-4 pt-3 flex flex-col md:justify-center justify-start md:items-center items-start bg-black  md:h-[23rem] w-full gap-6">
        <div className="flex md:flex-row flex-col justify-between md:items-center w-full md:gap-0 gap-6">
          <Emailform/>
          <div className="flex justify-between md:w-96">
            <div className="flex flex-col justify-start items-start space-y-4">
              <div className="">
                <h1 className="text-md uppercase">Follow Us</h1>
                <div className="h-0.5 w-32 bg-Primary rounded-full" />
              </div>{' '}
              <div className="flex flex-col justify-start items-start space-y-2">
                {social.map((items, index) => (
                  <div
                    key={index}
                    className="flex flex-row items-center space-x-3"
                  >
                    <img src={items.icon} alt="" className="md:w-6 w-5" />
                    <a
                      href={items.social}
                      className="uppercase cursor-pointer font-thin md:text-sm text-xs hover:underline hover:text-Primary hover:font-bold duration-150 ease-in-out"
                    >
                      <h1>{items.links}</h1>
                    </a>
                  </div>
                ))}
              </div>
            </div>
            <div className="flex flex-col justify-start items-start space-y-4">
              <div className="">
                <h1 className="text-md uppercase">Information</h1>
                <div className="h-0.5 w-36 bg-Primary rounded-full" />
              </div>{' '}
              <div className="flex flex-col justify-start items-start space-y-2">
                {social.map((items, index) => (
                  <div
                    key={index}
                    className="flex flex-row items-center space-x-3"
                  >
                    <h1 className="uppercase cursor-pointer font-thin md:text-sm text-xs duration-150 ease-in-out">
                      {items.info}
                    </h1>
                  </div>
                ))}
              </div>
            </div>{' '}
          </div>
        </div>
        <div className="space-y-3 w-full">
          <div className="h-0.5 w-full bg-white rounded-b-full" />
          <div className="flex flex-row justify-between items-center md:px-10">
            {nav.map((items, index) => (
              <Link
                key={index}
                spy={true}
                smooth={true}
                offset={0}
                duration={500}
                className="uppercase cursor-pointer font-thin md:text-sm text-xs hover:underline hover:text-Primary hover:font-bold duration-150 ease-in-out"
                to={items.scroll}
              >
                {items.navigation}
              </Link>
            ))}
          </div>
        </div>

        <div className="flex flex-col justify-center items-center w-full text-center md:text-sm text-xs font-light">
          <h1 className="flex items-center gap-1">
            <FaCopyright /> Copyright reserved 2024
          </h1>
          <h1>Develped by Tilet Tech</h1>
        </div>
      </div>{' '}
      <img
        src={img4}
        alt=""
        className="w-full h-[23rem] object-cover visible hidden"
      />
    </div>
  )
}

export default Footer
