import React, { useState } from "react";
import {
  Box,
  Button,
  ButtonGroup,
  Container,
  Select,
  Radio,
  MenuItem,
  IconButton,
  TextField,
  Typography,
  Dialog,
  DialogTitle,
  DialogContent,
  InputAdornment,
  InputBase,
  RadioGroup,
  FormControlLabel,
} from "@mui/material";
import { MdAttachMoney, MdOutlineDriveFileRenameOutline } from "react-icons/md";
import { CiLocationArrow1 } from "react-icons/ci";
import { IoBagOutline } from "react-icons/io5";
import { MdOutlineEmail } from "react-icons/md";
import { IoPhonePortraitOutline } from "react-icons/io5";
import Close from "../../Assets/icons/Asset 1Close.png";
import axios from "axios";
import Success from "../Success";
import Error from "../error";
import { useNavigate } from "react-router-dom";

const Popup = (props) => {
  const { title, subTitle, children, openPopup, setOpenPopup } = props;
  const nav = useNavigate();
  const [formData, setFormData] = useState({
    First_Name: "",
    Last_Name: "",
    Age: 0,
    Sex: "Male",
    Address: "",
    Occupation: "",
    Email: "",
    Phone: "",
    Additional_Phone_no: "",
    Amount: "",
  });
  const [upload, setupload] = useState("");
  const [success, setSuccess] = useState(false);
  const [error, setError] = useState(false);
  const [isValid, setIsValid] = useState(true);

  const handlePhoneNumChange = (event) => {
    const inputValue = event.target.value;
    const regex = /^(\+251)?[0-9 ()+-]*$/;

    if (!regex.test(inputValue)) {
      setIsValid(false);
      alert("Invalid phone number");
    } else if (inputValue.length !== 11) {
      setIsValid(true);
      handleChange(event);
    }
  };

  const handleChange = (e) => {
    const { name, value } = e.target;
    setFormData({ ...formData, [name]: value });
  };

  const handleSubmit = async (e) => {
    e.preventDefault();
    try {
      const response = await axios.post(
        "https://api.octoberbreastcancer.com/api/Donar",
        formData
      );
      console.log("Response:", response.data);
      const formDataUpload = new FormData();
      formDataUpload.append("image", upload);

      const uploadResponse = await axios.post(
        `https://api.octoberbreastcancer.com/api/Upload/image/Donar/${response.data}`,
        formDataUpload,
        {
          headers: {
            "Content-Type": "multipart/form-data",
          },
        }
      );
      console.log("Upload successful", uploadResponse.data);
      setFormData({
        First_Name: "",
        Last_Name: "",
        Age: 0,
        Sex: "Male",
        Address: "",
        Occupation: "",
        Email: "",
        Phone: "",
        Additional_Phone_no: "",
        Amount: "",
      });
      setupload("");
      setSuccess(true); // Clear the upload state
    } catch (error) {
      console.error("Error:", error);
      // Handle error (e.g., show an error message)
    }
  };

  return (
    <Dialog
      open={openPopup}
      maxWidth={100}
      className="rounded-3xl flex overflow-hidden"
    >
      <DialogTitle display="flex" justifyContent="space-between" className="">
        <Box>
          <Typography
            variant="h6"
            fontWeight="bold"
            fontSize="1.5em"
            sx={{ color: "#FF7FCE" }}
          >
            One Time Donation Form <br />
          </Typography>
          <Typography
            variant="h6"
            fontSize="0.9em"
            sx="text-[#a3a3a3] text-m"
            className="text-[#a3a3a3] text-m"
          >
            Pleas Fill the Following Form To Make Your Donation
          </Typography>
        </Box>
        <IconButton onClick={() => setOpenPopup(false)}>
          <img src={Close} alt="Close" width={30} height={30} />
        </IconButton>
      </DialogTitle>

      <Box
        fullWidth
        display="flex"
        gap={7}
        className="flex flex-col  lg:flex-row px-6"
      >
        <form
          onSubmit={handleSubmit}
          className="flex md:flex-row flex-col gap-9 pb-10"
        >
          <Box className="">
            <Box display="flex" justifyContent="space-evenly" gap="5%">
              <TextField
                fullWidth
                type="text"
                required
                placeholder="First Name"
                name="First_Name"
                value={formData.First_Name}
                onChange={handleChange}
                variant="standard"
                InputProps={{
                  endAdornment: (
                    <InputAdornment position="end" className="w-">
                      <MdOutlineDriveFileRenameOutline />
                    </InputAdornment>
                  ),
                }}
                className=""
                sx={{
                  backgroundColor: "#ffffff",
                  color: "#000000",
                  marginBottom: "5%",
                }}
              />
              <TextField
                fullWidth
                type="text"
                required
                placeholder="Last Name"
                name="Last_Name"
                value={formData.Last_Name}
                onChange={handleChange}
                variant="standard"
                InputProps={{
                  endAdornment: (
                    <InputAdornment position="end" className="w-">
                      <MdOutlineDriveFileRenameOutline />
                    </InputAdornment>
                  ),
                }}
                className=""
                sx={{
                  backgroundColor: "#ffffff",
                  color: "#000000",
                  marginBottom: "5%",
                }}
              />
            </Box>
            <Box className="lg:flex">
              <Box display="flex" alignItems="center">
                <Typography variant="h6" fontSize="1em">
                  Select Your Age
                </Typography>
                <TextField
                  type="number"
                  required
                  sx={{ width: "27%", ml: "2%" }}
                  placeholder="18-30"
                  name="Age"
                  value={formData.Age}
                  onChange={handleChange}
                />
              </Box>
              <Box className="flex flex-row gap-5" alignItems="center">
                <Typography variant="h6" fontSize="1em">
                  Gender
                </Typography>
                <RadioGroup
                  defaultValue="male"
                  value={formData.Sex}
                  name="Sex"
                  onChange={handleChange}
                  sx={{
                    flexDirection: { xs: "row", lg: "column" },
                  }}
                >
                  <FormControlLabel
                    required
                    value="male"
                    control={<Radio />}
                    label="Male"
                  />
                  <FormControlLabel
                    required
                    value="female"
                    control={<Radio />}
                    label="Female"
                  />
                </RadioGroup>
              </Box>
            </Box>

            <TextField
              fullWidth
              type="text"
              required
              placeholder="Address/Location"
              name="address"
              value={formData.address} // Changed to lowercase "address"
              onChange={handleChange} // Assuming handleChange is defined and correctly updating state
              variant="standard"
              InputProps={{
                endAdornment: (
                  <InputAdornment position="end">
                    <CiLocationArrow1 />
                  </InputAdornment>
                ),
              }}
              sx={{
                backgroundColor: "#ffffff",
                color: "#000000",
                marginBottom: "5%",
              }}
            />

            <TextField
              fullWidth
              type="text"
              placeholder="Occupation"
              required
              name="Occupation"
              value={formData.Occupation}
              onChange={handleChange}
              variant="standard"
              InputProps={{
                endAdornment: (
                  <InputAdornment position="end">
                    <IoBagOutline />
                  </InputAdornment>
                ),
              }}
              sx={{
                backgroundColor: "#ffffff",
                color: "#000000",
                marginBottom: "5%",
              }}
            />
            <TextField
              fullWidth
              type="email"
              required // lowercase "email" instead of "Email"
              placeholder="Email Address"
              name="email"
              value={formData.email} // lowercase "email" in formData
              onChange={handleChange} // Assuming handleChange is correctly defined and updating state
              variant="standard"
              InputProps={{
                endAdornment: (
                  <InputAdornment position="end">
                    <MdOutlineEmail />
                  </InputAdornment>
                ),
              }}
              sx={{
                backgroundColor: "#ffffff",
                color: "#000000",
                marginBottom: "5%",
              }}
            />

            <Box display="flex" justifyContent="space-between" gap="5%">
            <TextField
                fullWidth
                type="text"
                required
                placeholder="Phone Number"
                name="Phone" // Use uppercase "Phone" to match state key
                value={formData.Phone} // Use uppercase "Phone" in formData
                onChange={handlePhoneNumChange}
                variant="standard"
                InputProps={{
                  endAdornment: (
                    <InputAdornment position="end">
                      <IoPhonePortraitOutline />
                    </InputAdornment>
                  ),
                }}
                sx={{
                  backgroundColor: "#ffffff",
                  color: "#000000",
                  marginBottom: "5%",
                }}
              />

              <TextField
                fullWidth
                type="text"
                placeholder="Additional Phone (Optional)"
                name="phoneTwo"
                value={formData.phoneTwo} // Updated to match the field name in formData
                onChange={handlePhoneNumChange}
                variant="standard"
                InputProps={{
                  endAdornment: (
                    <InputAdornment position="end">
                      <IoPhonePortraitOutline />
                    </InputAdornment>
                  ),
                }}
                sx={{
                  backgroundColor: "#ffffff",
                  color: "#000000",
                  marginBottom: "5%",
                }}
              />
            </Box>
            <TextField
              fullWidth
              type="text"
              required
              placeholder="Amount"
              name="amount"
              value={formData.amount} // Updated to match the field name in formData
              onChange={handleChange}
              variant="standard"
              InputProps={{
                endAdornment: (
                  <InputAdornment position="end">
                    <MdAttachMoney />
                  </InputAdornment>
                ),
              }}
              sx={{
                backgroundColor: "#ffffff",
                color: "#000000",
                marginBottom: "5%",
              }}
            />
          </Box>

          <Box>
            <Box
              className="flex flex-col lg:flex-row text-center"
              gap={5}
              sx={{ marginBottom: "5%" }}
            >
              <img
                src="https://upload.wikimedia.org/wikipedia/en/6/6c/CBE_SA.png"
                width="120"
                alt="CBE Logo"
                className="mx-auto"
              />
              <Box>
                <Typography variant="h6" fontSize="1.1em">
                  <span>CBE Account Name</span>
                  <br />
                  <span className="text-[#FF7FCE] font-extrabold">
                    October Breast Cancer Support Organization
                  </span>
                </Typography>
                <Typography variant="h6" fontSize="1.1em">
                  <span>CBE Account Number</span>
                  <br />
                  <span className="text-[#FF7FCE] font-extrabold text-xl">
                    1000583316861
                  </span>
                </Typography>
              </Box>
            </Box>

            <TextField
              fullWidth
              type="file"
              required
              variant="standard"
              onChange={(e) => {
                const file = e.target.files[0]; // Extract the file from the event
                setupload(file); // Update the upload state with the selected file
              }}
              className=""
              sx={{
                backgroundColor: "#ffffff",
                color: "#000000",
                marginBottom: "5%",
              }}
            />

            <Typography variant="h6" fontSize="1.1em">
              <span className="text-[#a3a3a3] text-m">
                Once Paid, Please Upload A Screenshot Of Your Bill In The Box
                Above. Thank You For Your Patience.
              </span>
            </Typography>
            <Box className="flex flex-col justify-end items-end mt-5">
              <button
                size="large"
                fontWeight="bold"
                fontSize="1.2em"
                align="center"
                type="submit"
                className="hover:bg-black text-white bg-Primary py-2 px-9 duration-150 rounded-lg"
              >
                SUBMIT DONATION
              </button>
              {success && (
                <Success
                  text={"donated"}
                  handleclose={() => {
                    nav("/");
                  }}
                />
              )}
              {error && (
                <Error
                  handleClose={() => {
                    setError(false);
                  }}
                />
              )}
            </Box>
          </Box>
        </form>
      </Box>
    </Dialog>
  );
};

export default Popup;
