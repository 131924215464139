import { useState } from "react";
import BackNav from "../../Component/Navigation/BackNav";
import { BiCheckCircle, BiCloudUpload } from "react-icons/bi";
import { Box, Button, Typography } from "@mui/material";
import axios from "axios";
import { useNavigate, useParams } from "react-router-dom";
import Success from "../../Component/Success";

const StepThree = () => {
  const { id } = useParams();
  const nav = useNavigate();
  const [selectedFile, setSelectedFile] = useState(null);
  const [imageUrl, setImageUrl] = useState(null);
  const [uploadSuccess, setUploadSuccess] = useState(false);
  const [succes, setSuccess] = useState(false);
  const handleFileChange = (e) => {
    const file = e.target.files[0];
    setSelectedFile(file);
    const url = URL.createObjectURL(file);
    setImageUrl(url);
    uploadImage(file); // Automatically upload the image when selected
  };

  const uploadImage = async (image) => {
    try {
      const formData = new FormData();
      formData.append("image", image);
      const response = await axios.post(
        `https://api.octoberbreastcancer.com/api/Upload/image/Member/${id}`,
        formData,
        {
          headers: {
            "Content-Type": "multipart/form-data",
          },
        }
      );
      console.log(response.data.token);
      console.log("Image uploaded successfully:", response.data);
      setUploadSuccess(true);
      setSuccess(true);
    } catch (error) {
      console.error("Error uploading image:", error);
    }
  };

  return (
    <div className="">
      <BackNav />
      <div className="flex justify-center items-center h-screen md:px-10 px-4 overflow-hidden">
        <div className="w-full">
          <div className="flex md:flex-row flex-col justify-between items-center gap-6">
            <div className="flex justify-center items-center bg-Secondary p-3 gap-2 rounded-lg w-full">
              <img
                src="https://upload.wikimedia.org/wikipedia/en/6/6c/CBE_SA.png"
                alt=""
                className="w-56"
              />
              <div className="flex flex-col gap-3">
                <h1 className="font-xs text-lg text-Primary">
                  CBE account name:{" "}
                  <span className="font-bold uppercase text-black">
                    {" "}
                    October Breast Cancer Support Organization
                  </span>
                </h1>
                <h1 className="font-xs text-lg text-Primary">
                  CBE Account Number:{" "}
                  <span className="font-bold uppercase text-black">
                    {" "}
                    1000583316861
                  </span>
                </h1>
                <p className="text-gray-400">
                  Once Paid, Please Upload A Screenshot Of Your Bill In The Box
                  Above. Thank You For Your Patience.
                </p>
              </div>
            </div>

            <div className="flex justify-center items-center bg-Secondary p-3 gap-2 rounded-lg w-full">
              <label htmlFor="upload-input">
                {uploadSuccess ? (
                  <img src={imageUrl} alt="Uploaded" className="w-[25rem]" />
                ) : (
                  <BiCloudUpload className="text-[14rem] cursor-pointer text-gray-100" />
                )}
              </label>
              <input
                id="upload-input"
                type="file"
                accept="image/*"
                onChange={handleFileChange}
                style={{ display: "none" }}
              />
            </div>
          </div>
          {succes && (
            <Success
              text={"became a member"}
              handleclose={() => {
                nav("/");
              }}
            />
          )}

          <Box
            display="flex"
            justifyContent="space-evenly"
            className="md:w-1/2 mx-auto px-4"
            sx={{ marginTop: "6%" }}
          >
            <Typography
              variant="h6"
              fontSize="1.4em"
              textAlign="center"
              fontWeight="bold"
              className="bg-[#FF7FCE] rounded-full p-1 md:w-12 w-14 md:h-12 h-11 text-white"
            >
              {" "}
              1{" "}
            </Typography>
            <hr className="w-[40%] h-[4%] my-auto" />
            <Typography
              variant="h6"
              fontSize="1.4em"
              textAlign="center"
              fontWeight="bold"
              align="center"
              className="bg-[#FF7FCE] rounded-full p-1 md:w-12 w-14 md:h-12 h-11 text-white"
            >
              {" "}
              2
            </Typography>
            <hr className="w-[40%] h-[4%] my-auto" />
            <Typography
              variant="h6"
              fontSize="1.4em"
              textAlign="center"
              fontWeight="bold"
              className="bg-[#FF7FCE] rounded-full p-1 md:w-12 w-14 md:h-12 h-11 text-white"
            >
              {" "}
              3{" "}
            </Typography>
          </Box>
        </div>
      </div>
    </div>
  );
};
export default StepThree;
