import React, { useEffect, useState } from 'react'
import Cards from '../../Component/News cards/Cards'
import {
  Autoplay,
  Navigation,
  Pagination,
  Scrollbar,
  A11y,
} from 'swiper/modules'
import { Swiper, SwiperSlide } from 'swiper/react'
import 'swiper/css'
import 'swiper/css/navigation'
import 'swiper/css/pagination'
import 'swiper/css/scrollbar'
import { Element } from 'react-scroll'
import axios from 'axios'
import img2 from '../../Assets/images/img2.jpg'
import img6 from '../../Assets/images/img6.jpg'

const Newsfeed = () => {
  const [newsData, setnewsData] = useState([]);
  useEffect(() => {
    axios
      .get("https://api.octoberbreastcancer.com/api/News")
      .then((response) => {
        // Assuming the response data is an array of news items
        setnewsData(response.data);
      })
      .catch((error) => {
        console.error("Error fetching news data:", error);
      });
  }, []);
  return (
    <Element id="newsfeed" className="md:px-10 px-4 pt-9 text-end">
      <h1 className="Header">
        News <span className="Title">Feed</span>
      </h1>
      <div className="py-5 mx-auto">
        <Swiper
          modules={[Autoplay, Navigation, Pagination, Scrollbar, A11y]}
          spaceBetween={50}
          // Set a default value
          loop={true}
          autoplay={{
            delay: 2500,
            disableOnInteraction: false,
          }}
          pagination={{ clickable: true }}
          breakpoints={{
            // Set different settings for different screen sizes
            640: {
              slidesPerView: 1,
            },
            768: {
              slidesPerView: 2,
            },
            1024: {
              slidesPerView: 3,
            },
          }}
        >
          {(newsData.slice(0,7)).map((item, index) => (
            <SwiperSlide key={index}>
              <div className="flex justify-center items-center pb-12">
                {' '}
                <Cards
                link={'https://articles.octoberbreastcancer.com/newsfeed'}
                  image={`https://api.octoberbreastcancer.com/api/image/Get/News_feed/${item.NewsId}/${item.Image_1}`}
                  Headline={item.Title}
                  News={item.Body}
                />
              </div>
            </SwiperSlide>
          ))}
        </Swiper>
      </div>
    </Element>
  )
}

export default Newsfeed
