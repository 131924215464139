import React from 'react'
import {
  Autoplay,
  Navigation,
  Pagination,
  Scrollbar,
  A11y,
} from 'swiper/modules'
import { Swiper, SwiperSlide } from 'swiper/react'
import 'swiper/css'
import 'swiper/css/navigation'
import 'swiper/css/pagination'
import 'swiper/css/scrollbar'
import TestimonalCards from '../../Component/Testimonal Cards/TestimonalCards'
import user from '../../Assets/icons/user.png'
import testimone1 from '../../Assets/team/Mulu Engida.jpg'

const Testimonial = () => {
  const slide = [
    {
      img: testimone1,
      name: 'Mulu Engida',
      testimonial:
        'After I heard my diagnosis I promised myself if I survived this disease I would dedicate my life to raising awareness about cancer in my community and being an ambassador in the center made my dream one step closer to reality.',
    },
    {
      img: user,
      name: 'Anonymous',
      testimonial:
        'Within a week, I gained not only confidence but also a sense of relief. Walking into my classroom became a confident stride, thanks to this small yet impactful addition to my journey',
    },
      {
      img: testimone1,
      name: 'Mulu Engida',
      testimonial:
        'After I heard my diagnosis I promised myself if I survived this disease I would dedicate my life to raising awareness about cancer in my community and being an ambassador in the center made my dream one step closer to reality.',
    },
    {
      img: user,
      name: 'Anonymous',
      testimonial:
        'Within a week, I gained not only confidence but also a sense of relief. Walking into my classroom became a confident stride, thanks to this small yet impactful addition to my journey',
    }
  ]
  return (
    <div className="md:px-10 px-4">
      <h1 className="Header text-center">Testimonals</h1>
      <div className="py-5">
        <Swiper
          modules={[Autoplay, Navigation, Pagination, Scrollbar, A11y]}
          spaceBetween={50}
          loop={true}
          breakpoints={{
            640: {
              slidesPerView: 1,
            },
            760: {
              slidesPerView: 1,
            },
            1080: {
              slidesPerView: 1,
            },
          }}
          autoplay={{
            delay: 7000,
            disableOnInteraction: false,
          }}
        >
          {slide.map((items, index) => (
            <SwiperSlide key={index}>
              <TestimonalCards
                image={items.img}
                Name={items.name}
                testimone={items.testimonial}
              />
            </SwiperSlide>
          ))}
        </Swiper>{' '}
      </div>
    </div>
  )
}

export default Testimonial
