import { Box, Button, IconButton, TextField, Typography } from "@mui/material";
import React, { useState } from "react";
import InputAdornment from "@mui/material/InputAdornment";
import { MdOutlineDriveFileRenameOutline } from "react-icons/md";
import { HiUser } from "react-icons/hi2";
import { FaPhoneAlt } from "react-icons/fa";
import { MdEmail } from "react-icons/md";
import { RiEyeFill, RiEyeOffFill, RiLockPasswordFill } from "react-icons/ri";
import { FcGoogle } from "react-icons/fc";
import { useFormik } from "formik";
import Success from "../Component/Success";
import vector from "../Assets/logo/breast cancer vector 2@4x.png";
import BackNav from "../Component/Navigation/BackNav";
import axios from "axios";
import { useGoogleLogin } from "@react-oauth/google";
import { Link, useNavigate } from "react-router-dom";
import Error from "../Component/error";
import { useAuth } from "../Authentication";
import { AiOutlineLoading3Quarters } from "react-icons/ai";

const SignUp = () => {
  const nav = useNavigate();
  const [firstname, setfirstname] = useState();
  const [lastName, setlastname] = useState();
  const [username, setusername] = useState();
  const [phonenum, setphonenum] = useState();
  const [email, setemail] = useState();
  const [password, setpassword] = useState();
  const [confpass, setconfpass] = useState();
  const [loading, setLoading] = useState(false);
  const [error, setError] = useState(false);
  const [success, setSuccess] = useState(false);
  const [message, setMessage] = useState(false);
  const { authenticated, setAuthenticated } = useAuth(false);
  const [showPassword, setShowPassword] = useState(false);
  const [isValid, setIsValid] = useState(true);

   const handlePhoneNumChange = (event) => {
    const inputValue = event.target.value;
    const regex = /^(\+251)?[0-9 ()+-]*$/;

    if (!regex.test(inputValue)) {
      setIsValid(false);
      alert("Invalid phone number");
    } else if (inputValue.length !== 11) {
      setIsValid(true);
      setphonenum(inputValue);
    }
  };

  const handleTogglePasswordVisibility = () => {
    setShowPassword((prevShowPassword) => !prevShowPassword);
  };

  const handlesubmit = async (e) => {
    e.preventDefault();

    try {
      if (password !== confpass) {
        setMessage(true);
      } else {
        const response = await axios.post(
          "https://api.octoberbreastcancer.com/api/Sign_Up",
          {
            First_Name: firstname,
            Last_Name: lastName,
            User_Name: username,
            Email: email,
            Phone_Number: phonenum,
            Password: password,
          }
        );
        setSuccess(true);
        setAuthenticated(true);
        setLoading(false)
        console.log(response.data);
      }
    } catch (err) {
      setError(true);
    }
  };
  const login = useGoogleLogin({
    onSuccess: (credentialResponse) => {
      console.log(credentialResponse);
      alert("you have logged in");
    },
    onError: () => {
      console.log("Login Failed");
    },
  });
  return (
    <Box>
      <BackNav />
      <Box className="flex flex-col-reverse gap-9 lg:flex-row justify-center lg:justify-between items-center lg:items-start bg-white md:px-10 px-4 py-3">
        <Box className="bg-Secondary p-4 rounded-lg">
          <Typography
            fontSize="2.5em"
            textTransform="capitalize"
            textAlign="center"
            sx={{ marginBottom: "3%" }}
          >
            Sign Up
          </Typography>
          <form onSubmit={handlesubmit}>
            <Box display="flex" justifyContent="space-evenly" gap="5%">
              <TextField
                size="small"
                fullWidth
                type="text"
                placeholder="First Name"
                name="firstname"
                required
                onChange={(e) => setfirstname(e.target.value)}
                InputProps={{
                  startAdornment: (
                    <InputAdornment position="start" className="w-">
                      <MdOutlineDriveFileRenameOutline />
                    </InputAdornment>
                  ),
                }}
                className=""
                sx={{
                  backgroundColor: "#ffffff",
                  color: "#000000",
                  marginBottom: "5%",
                }}
              />
              <TextField
                size="small"
                fullWidth
                type="text"
                placeholder="Last Name"
                name="lastName"
                required
                onChange={(e) => setlastname(e.target.value)}
                InputProps={{
                  startAdornment: (
                    <InputAdornment position="start" className="w-">
                      <MdOutlineDriveFileRenameOutline />
                    </InputAdornment>
                  ),
                }}
                className=""
                sx={{
                  backgroundColor: "#ffffff",
                  color: "#000000",
                  marginBottom: "5%",
                }}
              />
            </Box>

            <TextField
              size="small"
              fullWidth
              type="text"
              placeholder="Username"
              name="username"
              required
              onChange={(e) => setusername(e.target.value)}
              InputProps={{
                startAdornment: (
                  <InputAdornment position="start" className="w-">
                    <HiUser />
                  </InputAdornment>
                ),
              }}
              sx={{
                backgroundColor: "#ffffff",
                color: "#000000",
                marginBottom: "7%",
              }}
            />
            <TextField
              size="small"
              fullWidth
              type="tel"
              name="phonenum"
              required
              placeholder="+251-"
              onChange={handlePhoneNumChange}
              InputProps={{
                startAdornment: (
                  <InputAdornment position="start" className="w-">
                    <FaPhoneAlt />
                  </InputAdornment>
                ),
              }}
              sx={{
                backgroundColor: "#ffffff",
                color: "#000000",
                marginBottom: '7%'
              }}
            />
            <TextField
              size="small"
              fullWidth
              type="email"
              placeholder="Email"
              name="email"
              required
              onChange={(e) => setemail(e.target.value)}
              InputProps={{
                startAdornment: (
                  <InputAdornment position="start" className="w-">
                    <MdEmail />
                  </InputAdornment>
                ),
              }}
              sx={{
                backgroundColor: "#ffffff",
                color: "#000000",
                marginBottom: "7%",
              }}
            />
            <TextField
              size="small"
              fullWidth
              type={showPassword ? "text" : "password"}
              placeholder="Password"
              name="password"
              value={password}
              onChange={(e) => setpassword(e.target.value)}
              InputProps={{
                startAdornment: (
                  <InputAdornment position="start">
                    <RiLockPasswordFill />
                  </InputAdornment>
                ),
                endAdornment: (
                  <InputAdornment position="end">
                    <IconButton
                      onClick={handleTogglePasswordVisibility}
                      edge="end"
                      aria-label="toggle password visibility"
                    >
                      {showPassword ? <RiEyeFill /> : <RiEyeOffFill />}
                    </IconButton>
                  </InputAdornment>
                ),
              }}
              sx={{
                backgroundColor: "#ffffff",
                color: "#000000",
                marginBottom: "7%",
              }}
            />
            <TextField
              size="small"
              fullWidth
              type={showPassword ? "text" : "password"}
              placeholder="Confirm Password"
              name="password"
              value={confpass}
              onChange={(e) => setconfpass(e.target.value)}
              InputProps={{
                startAdornment: (
                  <InputAdornment position="start">
                    <RiLockPasswordFill />
                  </InputAdornment>
                ),
                endAdornment: (
                  <InputAdornment position="end">
                    <IconButton
                      onClick={handleTogglePasswordVisibility}
                      edge="end"
                      aria-label="toggle password visibility"
                    >
                      {showPassword ? <RiEyeFill /> : <RiEyeOffFill />}
                    </IconButton>
                  </InputAdornment>
                ),
              }}
              sx={{
                backgroundColor: "#ffffff",
                color: "#000000",
              }}
            />
            {message && (
              <h1 className="absolute text-red-600 p-2">
                Password doesn't match
              </h1>
            )}
            <button
              type="submit"
              disabled={loading} // Disable button when loading
              className="bg-Primary rounded-md font-semibold hover:bg-black w-full py-2 uppercase text-lg flex justify-center items-center text-white mt-3 duration-150 ease-in-out"
            >
              {loading ? (
                <AiOutlineLoading3Quarters className="loading-animation text-2xl text-bold" />
              ) : (
                "Sign Up"
              )}
            </button>
            {error && <Error />}
            <Typography className="flex justify-center items-center pt-3 gap-1">
              <span className="text-[#a3a3a3]">Already have an account?</span>{" "}
              <Link
                to="/signin"
                className="text-[#FE7FCD] hover:cursor-pointer font-semibold"
              >
                {" "}
                Sign In
              </Link>
            </Typography>
            {success && (
              <Success
                handleclose={() => {
                  nav("/");
                }}
                text={
                  "Welcome, you have successfully sign up in october breast cancer organiztion"
                }
              />
            )}
            {/* <Box
              display="flex"
              justifyContent="space-evenly"
              alignItems="center"
              sx={{ marginTop: "5%", marginBottom: "5%" }}
            >
              <hr className="w-1/2 border-[#c69ab5]" />
              <Typography fontSize="1.2em" sx={{ m: "2%" }}>
                Or
              </Typography>
              <hr className="w-1/2 border-[#c69ab5]" />
            </Box>

            <Button
              onClick={() => login()}
              fullWidth
              size="small"
              justifyContent="center"
              type="submit"
              className="bg-[#ffffff]"
              sx={{
                backgroundColor: "#ffffff",
                border: 1,
                borderColor: "#000000",
                color: "#000000",
              }}
            >
              <FcGoogle className="text-[1.7em] m-2" />
              Continue With Google
            </Button> */}
          </form>
        </Box>
        <Box className="lg:w-[170rem] w-full">
          <h1 className="lg:text-lg text-md lg:w-full lg:text-start text-center">
            <span className="text-4xl font-bold text-Primary">
              {" "}
              Discover a world of personalized content!{" "}
            </span>
            Sign up now to enjoy exclusive access to our engaging blogs, latest
            news, and interactive commenting. Let's dive in together!
          </h1>
          <Box className="lg:flex hidden justify-center items-center">
            <img src={vector} className="w-3/4" />
          </Box>
        </Box>
      </Box>
    </Box>
  );
};

export default SignUp;
