
import React from 'react'

const TestimonalCards = ({image, Name, testimone}) => {
  return (
    <div className="text-center flex flex-col items-center justify-center gap-1 rounded-lg py-5">
      <img src={image} alt="" className='rounded-full w-16 h-16 object-cover'/>
        <h1 className='font-bold text-lg'>
        {Name}
        </h1>
        <p className='text-gray-600 md:w-3/4'>
         {testimone}
        </p>
    </div>
  );
}

export default TestimonalCards


