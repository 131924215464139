import React from "react";
import TeamCards from "../../Component/Teams card/TeamCards";
import team1 from "../../Assets/team/Mulu Engida.jpg";
import team2 from "../../Assets/team/Dr. Michael Shawel Lemma.jpg";
import team3 from "../../Assets/team/Dr. Mahlet Shewangizaw Debrework.jpg";
import team4 from "../../Assets/team/Dr. Edilawit Abebaw.jpg";
import team5 from "../../Assets/team/Abeba Aysew.jpg";
import team6 from "../../Assets/team/Dr. Selam Sete.jpg";
import team7 from "../../Assets/team/Dr. Tara Wilfong.jpg";
import team8 from "../../Assets/team/Nahom Girma Belete.jpg";
import team9 from "../../Assets/team/Dr. Yehenaw Tadele Tenaw.jpg";
import team10 from "../../Assets/team/habtesh seifu.jpg";
import team11 from "../../Assets/team/Ruth.jpg";
import Noimage from "../../Assets/icons/no-image.svg";
import {
  Autoplay,
  Navigation,
  Pagination,
  Scrollbar,
  A11y,
} from "swiper/modules";
import { Swiper, SwiperSlide } from "swiper/react";
import "swiper/css";
import "swiper/css/navigation";
import "swiper/css/pagination";
import "swiper/css/scrollbar";
import { Element } from "react-scroll";

const MeetTeams = () => {
  const team = [
    {
      Image: team1,
      Name: "Mulu Engida",
      Position: "Ambassador",
      Facebooklink: "",
      Linkedinlink: "",
      Instagramlink: "",
      Telegramlink: "",
    },
    {
      Image: Noimage,
      Name: "Dr. Biruk Habtamu",
      Position:
        "Assistant Professor of Clinical Oncology | " +
        "Head of Ali Birra Cancer Treatment Center Haramaya University",
      Facebooklink: "",
      Linkedinlink: "",
      Instagramlink: "",
      Telegramlink: "",
    },
    {
      Image: team2,
      Name: "Dr. Michael Shawel Lemma",
      Position:
        "Executive Board of Director | Assistant Professor of Clinical Oncology",
      Facebooklink: "",
      Linkedinlink:
        "https://www.linkedin.com/in/michael-lemma-05b995159?utm_source=share&utm_campaign=share_via&utm_content=profile&utm_medium=android_app",
      Instagramlink:
        "https://www.instagram.com/dr_mikeshawel?igsh=MWxiNWR0ODBhNWM4aA==",
      Telegramlink: "https://www.tiktok.com/@dr.mikedire?_t=8jWfxgJNjmv&_r=1 ",
    },
    {
      Image: team3,
      Name: "Dr. Mahlet Shawangizaw",
      Position: "Secretary and Finance Manager | General physician",
      Facebooklink:
        "https://www.facebook.com/mahlet.shewangizaw.3?mibextid=ZbWKwL ",
      Linkedinlink:
        "https://www.linkedin.com/in/mahletshewangizawharariwa?utm_source=share&utm_campaign=share_via&utm_content=profile&utm_medium=android_app ",
      Instagramlink:
        "https://www.instagram.com/mahletshewa?igsh=ZHhodTJ3NjdyNmpx ",
      Telegramlink: "",
    },
    {
      Image: team9,
      Name: "Dr. Yehenaw Tadele",
      Position: "Project Manager",
      Facebooklink: "",
      Linkedinlink:
        "https://www.linkedin.com/search/results/all/?fetchDeterministicClustersOnly=true&heroEntityKey=urn%3Ali%3Afsd_profile%3AACoAAC5HiNMBQEv6J07jHrIFT1lJ3gfRqKaWj8k&keywords=yehenaw%20tadele%20tenaw&origin=RICH_QUERY_SUGGESTION&position=0&searchId=399d3f66-4dd2-4cf9-8ca9-6c8046961df0&sid=0-x&spellCorrectionEnabled=false",
      Instagramlink: "https://www.instagram.com/yentad1/ ",
      Telegramlink: "",
    },
    {
      Image: team4,
      Name: "Dr. Edilawit Ababaw",
      Position: "Public Relations | General pyhsician",
      Facebooklink: "https://www.facebook.com/profile.php?id=100006269838821 ",
      Linkedinlink:
        "https://www.linkedin.com/in/edilawit-abebaw-833a8b2a5?utm_source=share&utm_campaign=share_via&utm_content=profile&utm_medium=android_app ",
      Instagramlink:
        "https://www.instagram.com/dr_edilawit?utm_source=qr&igsh=MW0wMHZ6NGliZXMybg== ",
      Telegramlink: "https://www.tiktok.com/@dr_edilawit?_t=8jWaoYTgUBa&_r=1 ",
    },
    {
      Image: team10,
      Name: "Habtamu Seife",
      Position: "MSc Oncology Nurse Human Resource | Human resource",
      Facebooklink: "",
      Linkedinlink:
        "https://www.linkedin.com/in/habtamu-seife-411136a6?lipi=urn%3Ali%3Apage%3Ad_flagship3_profile_view_base_contact_details%3Ba8AQescAQy%2BpG1haAddaDQ%3D%3D ",
      Instagramlink: "",
      Telegramlink: "",
    },
    {
      Image: team5,
      Name: "Abeba Aysew",
      Position: "Reproductive Health Specialist | Public relations",
      Facebooklink: "",
      Linkedinlink: "https://www.linkedin.com/in/abeba-aysew-1246a42a6/",
      Instagramlink: "",
      Telegramlink: "",
    },
    {
      Image: team8,
      Name: "Nahom Girma Belete",
      Position: "Research Manager | Bsc. Nurse, MPH in Nutrition",
      Facebooklink: "",
      Linkedinlink:
        "https://www.linkedin.com/in/nahom-girma-a8b79922a?utm_source=share&utm_campaign=share_via&utm_content=profile&utm_medium=ios_app",
      Instagramlink: "",
      Telegramlink: "",
    },

    {
      Image: team6,
      Name: "Dr. Selam Sete",
      Position: "Dermatology Resident Research Assistant | Research assistant",
      Facebooklink: "",
      Linkedinlink:
        "https://www.linkedin.com/authwall?trk=bf&trkInfo=AQFU4AvS1YOU8QAAAY-ligEgs0e4782kGEy2TFEfe1vVGgH1_8k-AT3py6_UnE-M80Z8yH41e_I7h6pBrL5dasvhJn1WlpmKzESIjZP3zNdGJzlcUj8iJ-dzV3KIWXm155ADXAo=&original_referer=&sessionRedirect=https%3A%2F%2Fwww.linkedin.com%2Fin%2Fselam-sete-44b676281",
      Instagramlink: "",
      Telegramlink: "",
    },
    {
      Image: team11,
      Name: "Dr. Ruth Yirgalem",
      Position: "Family Medicine Resident Research Assistant | Research assistant",
      Facebooklink: "",
      Linkedinlink: "",
      Instagramlink: "",
      Telegramlink: "",
    },

    {
      Image: Noimage,
      Name: "Dr. Nahom Wondwossen",
      Position: "Assistant Professor of Clinical Oncology Member",
      Facebooklink: "",
      Linkedinlink: "",
      Instagramlink: "",
      Telegramlink: "",
    },
    {
      Image: team7,
      Name: "Dr. Tara Wilfong",
      Position: "Associate Professor, International Collaborations and Liaison Director | Haramaya University Honorary member",
      Facebooklink: "",
      Linkedinlink: "",
      Instagramlink: "",
      Telegramlink: "",
    },
  ];
  return (
    <Element id="aboutus" className="md:px-10 px-4 pt-9 text-start">
      <h1 className="Header">
        Meet our<span className="Title">Team</span>
      </h1>
      <div className="py-8">
        <Swiper
          modules={[Autoplay, Navigation, Pagination, Scrollbar, A11y]}
          spaceBetween={50}
          autoplay={{
            delay: 2500,
            disableOnInteraction: false,
          }}
          breakpoints={{
            640: {
              slidesPerView: 1,
            },
            768: {
              slidesPerView: 2,
            },
            1024: {
              slidesPerView: 3,
            },
          }}
          loop={true}
          pagination={{ clickable: true }}
        >
          {team.map((items, index) => (
            <SwiperSlide key={index}>
              <TeamCards
                Image={items.Image}
                Name={items.Name}
                Position={items.Position}
                Facebooklink={items.Facebooklink}
                Linkedinlink={items.Linkedinlink}
                Instagramlink={items.Instagramlink}
                Telegramlink={items.Telegramlink}
              />
            </SwiperSlide>
          ))}
        </Swiper>{" "}
      </div>
    </Element>
  );
};

export default MeetTeams;
