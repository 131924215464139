import {
  Box,
  TextField,
  Typography,
  RadioGroup,
  FormControlLabel,
  Radio,
  Button,
} from "@mui/material";
import React, { useState } from "react";
import InputAdornment from "@mui/material/InputAdornment";
import { MdEvent, MdOutlineDriveFileRenameOutline } from "react-icons/md";
import { FaPhoneAlt } from "react-icons/fa";
import { MdEmail } from "react-icons/md";
import { CiLocationArrow1 } from "react-icons/ci";
import { useNavigate } from "react-router-dom";
import BackNav from "../../Component/Navigation/BackNav";
import axios from "axios";

const StepOne = () => {
  const nav = useNavigate();
  const [formData, setformData] = useState({
    first_Name: "",
    last_Name: "",
    date_of_birth: "",
    email: "",
    gender: "",
    address: "",
    phone_number: "",
    qualification: "",
    specify_qualification: "",
    field_of_work_study: "",
    job_or_student_details: "",
    volunteer_opportunities: "",
  });
  const [isValid, setIsValid] = useState(true);

   const handlePhoneNumChange = (event) => {
    const inputValue = event.target.value;
    const regex = /^(\+251)?[0-9 ()+-]*$/;

    if (!regex.test(inputValue)) {
      setIsValid(false);
      alert("Invalid phone number");
    } else if (inputValue.length !== 11) {
      setIsValid(true);
      handlechange(event);
    }
  };

  const handlechange = (e) => {
    const { name, value } = e.target;
    setformData({ ...formData, [name]: value });
  };

  const handleSubmit = async (e) => {
    e.preventDefault();
    try {
      const response = await axios.post(
        "https://api.octoberbreastcancer.com/api/Members",
        formData
      );
      console.log(formData);
      console.log("Response:", response.data);
      nav(`/MembershipType/${response.data}`);
    } catch (error) {
      console.log(error);
    }
  };
  return (
    <Box className="">
      <BackNav />
      <form action="" onSubmit={handleSubmit}>
        <Box className="w-full flex md:flex-row flex-col md:px-12 px-4 md:gap-10 gap-4">
          <Box className="md:w-1/2 md:h-1/2 bg-Secondary shadow-sm p-5 rounded-lg ">
            <Typography
              fontWeight="bold"
              fontSize="2em"
              sx={{ marginTop: "7%", marginBottom: "3%" }}
            >
              MEMBERSHIP FORM
            </Typography>
            <Box display="flex flex-col" justifyContent="space-evenly" gap="5%">
              <Typography
                variant="h6"
                fontSize="1em"
                className="mr-3"
                fontWeight="bold"
                sx={{ marginBottom: "1%" }}
              >
                {" "}
                Write your answers here
              </Typography>
              <TextField
                fullWidth
                required
                size="small"
                type="text"
                placeholder="First Name"
                name="first_name"
                value={formData.first_name}
                onChange={handlechange}
                InputProps={{
                  startAdornment: (
                    <InputAdornment position="start" className="w-">
                      <MdOutlineDriveFileRenameOutline />
                    </InputAdornment>
                  ),
                }}
                className=""
                sx={{
                  backgroundColor: "#ffffff",
                  color: "#000000",
                  marginBottom: "5%",
                }}
              />
              <TextField
                fullWidth
                required
                size="small"
                type="text"
                placeholder="Last Name"
                name="lastName"
                value={formData.last_name}
                onChange={handlechange}
                InputProps={{
                  startAdornment: (
                    <InputAdornment position="start" className="w-">
                      <MdOutlineDriveFileRenameOutline />
                    </InputAdornment>
                  ),
                }}
                className=""
                sx={{
                  backgroundColor: "#ffffff",
                  color: "#000000",
                  marginBottom: "5%",
                }}
              />
            </Box>
            <TextField
              fullWidth
              required
              size="small"
              type="email"
              placeholder="Email"
              name="email"
              value={formData.email}
              onChange={handlechange}
              InputProps={{
                startAdornment: (
                  <InputAdornment position="start" className="w-">
                    <MdEmail />
                  </InputAdornment>
                ),
              }}
              className=""
              sx={{
                backgroundColor: "#ffffff",
                color: "#000000",
                marginBottom: "5%",
              }}
            />
            <Box
              className="flex flex-col"
              justifyContent="space-between"
              sx={{ marginBottom: "5%" }}
            >
              <Box>
                <Typography
                  variant="h6"
                  fontSize="1em"
                  className="mr-3"
                  fontWeight="bold"
                  sx={{ marginBottom: "5%" }}
                >
                  Date of birth
                </Typography>
                <TextField
                  fullWidth
                  size="small"
                  required
                  type="date"
                  name="date_of_birth"
                  value={formData.date_of_birth}
                  onChange={handlechange}
                  InputProps={{
                    startAdornment: (
                      <InputAdornment position="start">
                        <MdEvent />
                      </InputAdornment>
                    ),
                  }}
                  className=""
                  sx={{
                    backgroundColor: "#ffffff",
                    color: "#000000",
                    marginBottom: "5%",
                  }}
                  InputLabelProps={{
                    shrink: true,
                  }}
                />
              </Box>
              <Box className="flex flex-col" alignItems="">
                <Typography
                  variant="h6"
                  fontSize="1em"
                  className="mr-3"
                  fontWeight="bold"
                >
                  Gender
                </Typography>
                <RadioGroup
                  aria-labelledby="demo-radio-buttons-group-label"
                  defaultValue="male"
                  name="gender"
                  value={formData.gender}
                  onChange={handlechange}
                  className="grid grid-cols-2"
                >
                  <FormControlLabel
                    value="male"
                    control={<Radio />}
                    label="Male"
                  />
                  <FormControlLabel
                    value="female"
                    control={<Radio />}
                    label="Female"
                  />
                </RadioGroup>
              </Box>
            </Box>

            <TextField
              fullWidth
              required
              size="small"
              type="text"
              placeholder="Address/Location"
              name="address"
              value={formData.address}
              onChange={handlechange}
              InputProps={{
                startAdornment: (
                  <InputAdornment position="start" className="w-">
                    <CiLocationArrow1 />
                  </InputAdornment>
                ),
              }}
              className=""
              sx={{
                backgroundColor: "#ffffff",
                color: "#000000",
                marginBottom: "5%",
              }}
            />
            <TextField
              fullWidth
              required
              size="small"
              type="text"
              placeholder="Phone Number"
              name="phone_number"
              value={formData.phone_number}
              onChange={handlePhoneNumChange}
              InputProps={{
                startAdornment: (
                  <InputAdornment position="start">
                    <FaPhoneAlt />
                  </InputAdornment>
                ),
              }}
              className=""
              sx={{
                backgroundColor: "#ffffff",
                color: "#000000",
                marginBottom: "5%",
              }}
            />
          </Box>
          <Box className="md:w-1/2">
            <Box className=" bg-Secondary shadow-sm p-5 rounded-lg">
              <Box className="" alignItems="center" sx={{ marginBottom: "3%" }}>
                <Typography
                  variant="h6"
                  fontSize="1em"
                  className="mr-3"
                  fontWeight="bold"
                  sx={{ marginBottom: "1%" }}
                >
                  {" "}
                  Qualifications{" "}
                </Typography>
                <RadioGroup
                  aria-labelledby="demo-radio-buttons-group-label"
                  name="qualification" // Make sure this matches the name of the state property
                  value={formData.qualification}
                  onChange={handlechange} // Ensure that this function is correctly updating the state
                  className="flex"
                >
                  <FormControlLabel
                    value="student"
                    control={<Radio />} // Render the Radio button
                    label="Student"
                  />
                  <FormControlLabel
                    value="undergraduate"
                    control={<Radio />} // Render the Radio button
                    label="Undergraduate"
                  />
                  <FormControlLabel
                    value="graduate"
                    control={<Radio />} // Render the Radio button
                    label="Graduate"
                  />
                </RadioGroup>
              </Box>
              <Box sx={{ marginBottom: "3%" }}>
                <Typography
                  variant="h6"
                  fontSize="1em"
                  className="mr-3"
                  fontWeight="bold"
                  sx={{ marginBottom: "1%" }}
                >
                  {" "}
                  Specific Qualifications{" "}
                </Typography>
                <TextField
                  fullWidth
                  required
                  type="text"
                  variant="standard"
                  placeholder="Write Answers Here"
                  name="specificQualif" // Make sure this matches the corresponding property in the state
                  value={formData.specificQualif} // Ensure that this matches the state property
                  onChange={handlechange} // Make sure this function is correctly updating the state
                  className=""
                  sx={{ color: "#000000" }}
                />
              </Box>

              <Box className="" alignItems="center" sx={{ marginBottom: "3%" }}>
                <Typography
                  variant="h6"
                  fontSize="1em"
                  className="mr-3"
                  fontWeight="bold"
                  sx={{ marginBottom: "1%" }}
                >
                  {" "}
                  What field are you in{" "}
                </Typography>
                <RadioGroup
                  aria-labelledby="demo-radio-buttons-group-label"
                  defaultValue="healthCare" // Set the default value if needed
                  name="qualification" // Make sure this matches the corresponding property in the state
                  value={formData.qualification} // Ensure that this matches the state property
                  onChange={handlechange} // Make sure this function is correctly updating the state
                  className="flex"
                >
                  <FormControlLabel
                    value="healthCare"
                    required
                    control={<Radio />}
                    label="Health Care"
                  />
                  <FormControlLabel
                    value="admin"
                    required
                    control={<Radio />}
                    label="Administration"
                  />
                  <FormControlLabel
                    value="others"
                    required
                    control={<Radio />}
                    label="Others"
                  />
                </RadioGroup>
              </Box>

              <Box sx={{ marginBottom: "3%" }}>
                <Typography
                  variant="h6"
                  fontSize="1em"
                  className="mr-3"
                  fontWeight="bold"
                  sx={{ marginBottom: "1%" }}
                >
                  {" "}
                  Job/If student what year and department{" "}
                </Typography>

                <TextField
                  fullWidth
                  required
                  type="text"
                  variant="standard"
                  placeholder="Write Answers Here"
                  name="job_or_student_details" // Ensure this matches the corresponding property in the state
                  value={formData.job_or_student_details} // Ensure this matches the state property
                  onChange={handlechange} // Make sure this function is correctly updating the state
                  className=""
                  sx={{ color: "#000000" }}
                />
              </Box>
              <Box sx={{ marginBottom: "3%" }}>
                <Typography
                  variant="h6"
                  fontSize="1em"
                  className="mr-3"
                  fontWeight="bold"
                  sx={{ marginBottom: "1%" }}
                >
                  {" "}
                  Our volunteers are the heartbeat of our support for
                  individuals and families facing breast cancer. Join us in
                  making a meaningful difference. You can choose from a variety
                  of volunteer opportunities{" "}
                </Typography>

                <RadioGroup
                  aria-labelledby="demo-radio-buttons-group-label"
                  defaultValue="healthCare" // Set a default value if needed
                  value={formData.volunteer_opportunities} // Make sure this matches the state property
                  onChange={handlechange} // Ensure this function correctly updates the state
                  name="volunteer_opportunities" // Make sure this matches the corresponding property in the state
                  className="flex"
                >
                  <FormControlLabel
                    value="patientSupport"
                    control={<Radio />}
                    label="Patient support"
                  />
                  <FormControlLabel
                    value="fundraising"
                    control={<Radio />}
                    label="Fundraising"
                  />
                  <FormControlLabel
                    value="adminTasks"
                    control={<Radio />}
                    label="Administrative tasks"
                  />
                  <FormControlLabel
                    value="advocacy"
                    control={<Radio />}
                    label="Advocacy and mission delivery"
                  />
                  <FormControlLabel
                    value="informationPoint"
                    control={<Radio />}
                    label="Information point volunteer"
                  />
                  <FormControlLabel
                    value="others"
                    control={<Radio />}
                    label="Others"
                  />
                </RadioGroup>
              </Box>
            </Box>
          </Box>
        </Box>
        <Box className="flex justify-center items-center px-4">
          <Button
            size="large"
            fontWeight="bold"
            fontSize="1.2em"
            display="flex"
            type="submit"
            justifyContent="center"
            className="md:w-1/3 w-full hover:bg-black"
            align="center"
            sx={{
              backgroundColor: "#FF7FCE",
              color: "white",
              marginTop: "2%",
              marginBottom: "2%",
              "&:hover": {
                backgroundColor: "black",
              },
            }}
          >
            NEXT
          </Button>
        </Box>
      </form>
      <Box
        display="flex"
        justifyContent="space-evenly"
        className="md:w-1/2 mx-auto px-4"
        sx={{ marginBottom: "4%", marginTop: "4%" }}
      >
        <Typography
          variant="h6"
          fontSize="1.4em"
          textAlign="center"
          fontWeight="bold"
          className="bg-[#FF7FCE] rounded-full p-1 md:w-12 w-14 md:h-12 h-11 text-white"
        >
          {" "}
          1{" "}
        </Typography>
        <hr className="w-[40%] h-[4%] my-auto" />
        <Typography
          variant="h6"
          fontSize="1.4em"
          textAlign="center"
          fontWeight="bold"
          align="center"
          className="bg-gray-300 rounded-full p-1 md:w-12 w-14 md:h-12 h-11 text-white"
        >
          {" "}
          2{" "}
        </Typography>
        <hr className="w-[40%] h-[4%] my-auto" />
        <Typography
          variant="h6"
          fontSize="1.4em"
          textAlign="center"
          fontWeight="bold"
          className="bg-gray-300 rounded-full p-1 md:w-12 w-14 md:h-12 h-11 text-white"
        >
          {" "}
          3{" "}
        </Typography>
      </Box>
    </Box>
  );
};

export default StepOne;
