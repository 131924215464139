import React from "react";
import Facebook from "../../Assets/icons/Facebook.png";
import Instagram from "../../Assets/icons/Instagram.png";
import Linkedin from "../../Assets/icons/Linkedin.png";
import Tiktok from "../../Assets/icons/Tiktok.png";

const TeamCards = ({
  Image,
  Name,
  Position,
  Facebooklink,
  Linkedinlink,
  Instagramlink,
  Telegramlink,
}) => {
  return (
    <div className="flex flex-col justify-end items-center pb-6 w-full">
      <img
        src={Image}
        alt=""
        className="w-full rounded-t-lg object-cover aspect-square"
      />
      <div className="bg-Secondary flex flex-row justify-between items-center w-full h-28 px-4 py-1 mb-2 rounded-b-lg">
        <div className="flex flex-col justify-start items-start">
          <h1 className="font-bold text-xl">{Name}</h1>
          <p className="text-md text-gray-500 font-light">{Position}</p>
        </div>
        <div className="flex flex-row justify-center items-center gap-2">
          {Facebooklink ? (
            <a
              href={Facebooklink}
              target="_blank"
              className="h-full cursor-pointer"
            >
              <img
                src={Facebook}
                alt="Facebook"
                className="w-7 hover:scale-125 duration-150 ease-in-out"
              />
            </a>
          ) : (
            ""
          )}
          {Linkedinlink ? (
            <a
              href={Linkedinlink}
              target="_blank"
              className="h-full cursor-pointer"
            >
              <img
                src={Linkedin}
                alt="LinkedIn"
                className="w-7 hover:scale-125 duration-150 ease-in-out"
              />
            </a>
          ) : (
            ""
          )}
          {Instagramlink ? (
            <a
              href={Instagramlink}
              target="_blank"
              className="h-full cursor-pointer"
            >
              <img
                src={Instagram}
                alt="Instagram"
                className="w-7 hover:scale-125 duration-150 ease-in-out"
              />
            </a>
          ) : (
            ""
          )}
          {Telegramlink ? (
            <a
              href={Telegramlink}
              target="_blank"
              className="h-full cursor-pointer"
            >
              <img
                src={Tiktok}
                alt="Tiktok"
                className="w-7 hover:scale-125 duration-150 ease-in-out"
              />
            </a>
          ) : (
            ""
          )}
        </div>
      </div>
    </div>
  );
};

export default TeamCards;
