import React, { useEffect, useState } from "react";
import {
  Autoplay,
  Navigation,
  Pagination,
  Scrollbar,
  A11y,
} from "swiper/modules";
import { Swiper, SwiperSlide } from "swiper/react";
import "swiper/css";
import "swiper/css/navigation";
import "swiper/css/pagination";
import "swiper/css/scrollbar";
import { Element } from "react-scroll";
import axios from 'axios'
import img1 from "../../Assets/images/img1.jpg";
import img2 from "../../Assets/images/img2.jpg";
import { useNavigate } from "react-router-dom";

const Education = () => {
  const [eduData, seteduData] = useState([]);
  const navigate = useNavigate();

  const handleClick = () => {
    const url = "https://articles.octoberbreastcancer.com/cancereducation";
    window.open(url, '_blank', 'noopener,noreferrer');
  };
  useEffect(() => {
    axios
      .get("https://api.octoberbreastcancer.com/api/blog")
      .then((response) => {
        // Assuming the response data is an array of news items
        seteduData(response.data);
      })
      .catch((error) => {
        console.error("Error fetching news data:", error);
      });
  }, []);
  return (
    <Element id="cancereducation">
    <Swiper
      modules={[Autoplay, Navigation, Pagination, Scrollbar, A11y]}
      spaceBetween={50}
      slidesPerView={1}
      loop={true}
      autoplay={{
        delay: 4500,
        disableOnInteraction: false,
      }}
      pagination={{ clickable: true }}
      navigation={true}
      className="w-full">
      {(eduData.slice(0,5)).map((items, index) => (
        <SwiperSlide key={index}>
          <button onClick={handleClick} className="Gradient md:w-full w-[100%] md:h-full text-start md:px-10 px-4 py-2 ">
            <img
              src={`https://api.octoberbreastcancer.com/api/image/Get/Blog/${items.BlogId}/${items.Image_1}`}
              alt=""
              className="md:w-full w-[100%] md:h-[100%] h-full object-cover bg-center aspect-auto absolute right-0 top-0 -z-50"
            />
            <div className="md:space-y-[25rem] space-y-56 z-50 text-start">
              <h1 className="font-bold md:text-5xl text-3xl text-white">
                Cancer Education
              </h1>
              <div className="pb-4">
                <p className="md:text-4xl text-2xl font-semibold text-white">
                  {items.Title}
                </p>
                <h4 className="text-white ">{(items.Body).slice(0,199)}...</h4>
              </div>
            </div>
          </button>
        </SwiperSlide>
      ))}
    </Swiper>
    </Element>
  );
};

export default Education;
