import React from "react";

const Card = () => {
  const desc = [
    {
      header: "MISSION",
      description:
        "Providing comprehensive support and community for those affected by breast cancer and other cancers through education, advocacy, research funding, and wellness programs.",
    },
    {
      header: "VISION",
      description:
        "Empowering those affected by breast cancer to live their best lives by providing support, resources, and community. Promoting awareness, understanding, and access to quality care.",
    },
    {
      header: "VALUE",
      description:
        "Our value is rooted in its commitment to providing comprehensive support, community, and resources for individuals affected by breast cancer and other cancers, while promoting awareness, understanding, and access to quality care.",
    },
  ];
  return (
    <div className="flex md:flex-row flex-col justify-center items-center lg:gap-20 gap-9 px-10 py-4">
      {desc.map((items , index) => (
        <div key={index} className="flex flex-col justify-center items-center">
          <h1 className="md:text-4xl text-2xl">{items.header}</h1>
          <div className="h-1 lg:w-56 w-40 rounded-full bg-Primary" />
          <p className="text-center w-80 pt-3 text-gray-600">{items.description}</p>
        </div>
      ))}
    </div>
  );
};

export default Card;
