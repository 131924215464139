import React, { useState } from "react";
import Popup from "../../Component/Shared/Popup";
import { NavLink } from "react-router-dom";

const Donation = () => {
  const [openPopup, setOpenPopup] = useState(false);

  return (
    <div className="flex flex-col justify-center items-center space-y-5 bg-Secondary p-2">
      <Popup
        title="One Time Donation"
        subTitle="Fill the form below"
        openPopup={openPopup}
        setOpenPopup={setOpenPopup}
      >
      </Popup>
      <div className="text-center">
        <p>
          Please <span className="text-xl text-Primary font-bold">Donate</span>{" "}
          if you want to support our women for suffering from breast cancer
        </p>
      </div>
      <div className="flex md:flex-row flex-col justify-center items-center md:gap-56 gap-3 md:text-xl text-lg font-bold">
        <NavLink
          to="MembershipForm"
          className="bg-Primary text-white px-10 py-2 uppercase hover:bg-black duration-150 ease-in-out"
        >
          Become A Member
        </NavLink>

        <button
          onClick={() => setOpenPopup(true)}
          className="text-Primary uppercase hover:underline hover:text-black duration-150 ease-in-out"
        >
          One Time Donation
        </button>
      </div>
    </div>
  );
};

export default Donation;
