import React from 'react'
import logo from '../../Assets/logo/Logo only.png'

const BackNav = () => {
  return (
    <div className="flex flex-row justify-between items-center md:px-10 px-4 py-2 text-sm w-full">
      <div className="">
        <img src={logo} alt="" className="w-14" />
      </div>
      <a
        href={'/'}
        className="uppercase hover:underline hover:text-Primary cursor-pointer duration-100 ease-in-out"
      >
        Back to home
      </a>
    </div>
  )
}

export default BackNav
