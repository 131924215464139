import React from "react";
import { Element } from "react-scroll";

const whatwe = () => {
  return (
    <Element
      id="whatwedo"
      className="flex flex-col justify-center items-center gap-4 md:px-10 px-4 py-6"
    >
      <h1 className="Header text-center">
        What we are <span className="Title">Doing</span>
      </h1>
      <p className="md:w-4/6  text-center text-gray-600">
        October Breast Cancer Support Organization is a nonprofit organization
        created to provide support and assistance to those affected by breast
        cancer. It provides services ranging from awareness creation about
        cancer to initiating cancer centers to start psychosocial support
        groups. The organization is run by health professionals with
        multi-disciplinary backgrounds which include; Oncologists, Nurses,
        General practitioners, Psychiatrists, and Breast Cancer Survivors who
        have experience in dealing with physical and emotional challenges that
        come with fighting against breast cancer. October Breast Cancer Support
        Organization aims to integrate the social and community aspects with the
        medical and oncological services to help breast cancer patients cope and
        survive cancer and cancer treatments.
      </p>
    </Element>
  );
};

export default whatwe;
