import React, { useEffect, useState } from 'react'
import Homeimg from '../Assets/Breast-Cancer-Awareness_iStock-1139537242-1098x720.jpg'
import { Element } from 'react-scroll'

const Home = () => {
  const [currentTextIndex, setCurrentTextIndex] = useState(0)
  const [TextOpacity, setTextOpacity] = useState(1)
  const isPhone = window.innerWidth <= 640
  const text = [
    {
      Header: 'October',
      head: 'Breast Cancer Support Organization',
      motto: 'Empowering Hope, Fighting Cancer Together',
    },
    {
      Header: 'ኦክቶበር',
      head: 'የጡት ካንሰር መረዳጃ የበጎ አድራጎት ድርጅት',
      motto: 'Empowering Hope, Fighting Cancer Together',
    },
  ]

  const nextImage = () => {
    setTextOpacity(0)
    setTimeout(() => {
      setCurrentTextIndex((prevIndex) => (prevIndex + 1) % text.length)
      setTextOpacity(1)
    }, 1000)
  }

  useEffect(() => {
    const intervalId = setInterval(() => {
      nextImage()
    }, 6000)

    return () => {
      clearInterval(intervalId)
    }
  })
  return (
    <Element
      id="home"
      className="flex md:flex-row flex-col justify-between items-center md:h-full h-full md:pt-0 pt-8"
    >
      <div
        className="md:px-10 px-4  transition-opacity duration-1000 ease-in-out w-full h-full"
        style={{
          opacity: TextOpacity,
        }}
      >
        <h1 className="md:text-4xl text-3xl font-light">
          <span className="font-bold text-Primary md:text-6xl text-5xl">
            {text[currentTextIndex].Header}
          </span>{' '}
          {text[currentTextIndex].head}
        </h1>
        <p className="text-sm font-semibold text-gray-600">
          {text[currentTextIndex].motto}
        </p>
      </div>
      <div className="w-full h-full">
        <img
          src={Homeimg}
          alt=""
          className="w-full"
          style={{
            maskImage: isPhone
              ? 'linear-gradient(to bottom,transparent, black 50%)'
              : 'linear-gradient(to right, transparent, black 50%)',
          }}
        />
      </div>
    </Element>
  )
}

export default Home
