import { useState, useEffect, useRef } from "react";

const useSticky = (delay) => {
  const [isSticky, setIsSticky] = useState(false);
  const scrollRef = useRef();

  useEffect(() => {
    const handleScroll = () => {
      scrollRef.current = window.pageYOffset;

      if (scrollRef.current > delay) {
        setIsSticky(true);
      } else {
        setIsSticky(false);
      }
    };

    window.addEventListener("scroll", handleScroll);

    return () => {
      window.removeEventListener("scroll", handleScroll);
    };
  }, [delay]);

  return isSticky;
};

export default useSticky;