import {
  Route,
  RouterProvider,
  createBrowserRouter,
  createRoutesFromElements,
} from "react-router-dom";
import ScrollRoute from "./Route/ScrollRoute";
import SignIn from "./Pages/SignIn";
import SignUp from "./Pages/SignUp";
import StepOne from "./Container/Membership Form/StepOne";
import StepTwo from "./Container/Membership Form/StepTwo";
import StepThree from "./Container/Membership Form/StepThree";
import BackNav from "./Component/Navigation/BackNav";
import { useState } from "react";

function App() {
  const route = createBrowserRouter(
  createRoutesFromElements(
    <>
      {" "}
      <Route path="/" element={<ScrollRoute />} />
      <Route path="MembershipForm" element={<StepOne />} />
      <Route path="MembershipType/:id" element={<StepTwo />} />
      <Route path="MembershipPayment/:id" element={<StepThree />} />
      <Route path="signin" element={<SignIn />} />
      <Route path="signup" element={<SignUp />} />
    </>
  )
);
  return (
    <div>
      {/* <BackNav/> */}
      <RouterProvider router={route} className="overflow-hidden" />
    </div>
  );
}

export default App;
