import { Box, Button, Typography } from "@mui/material";
import React, { useState } from "react";
import { useNavigate, useParams } from "react-router-dom";
import Bronze from "../../Assets/icons/Asset 1Bronze.png";
import pointer from "../../Assets/icons/Asset 1pointer.png";
import Silver from "../../Assets/icons/Asset 2Silver.png";
import Gold from "../../Assets/icons/Asset 3Gold.png";
import Platinum from "../../Assets/icons/Asset 4platinium.png";
import BackNav from "../../Component/Navigation/BackNav";
import {
  Autoplay,
  Navigation,
  Pagination,
  Scrollbar,
  A11y,
} from "swiper/modules";
import { Swiper, SwiperSlide } from "swiper/react";
import "swiper/css";
import "swiper/css/navigation";
import "swiper/css/pagination";
import "swiper/css/scrollbar";
import axios from "axios";

const StepTwo = () => {
  const [handleClick, sethandleClick] = useState(true);
  const [selectedMembershipType, setSelectedMembershipType] = useState(null);
  const nav = useNavigate();
  const handleCardSelect = (membershipType) => {
    setSelectedMembershipType(membershipType);
  };

  const handleNext = async () => {
    // Send selected membership type to the database
    if (selectedMembershipType) {
      const requestData = {
        membership_type: selectedMembershipType,
      };
  
      try {
        const response = await axios.post("https://api.octoberbreastcancer.com/api/Members", requestData);
        console.log("Data sent to database:", response.data);
        // Navigate to the next step
        nav(`/MembershipPayment/${response.data}`);
      } catch (error) {
        console.error("Error sending data to database:", error);
      }
    } else {
      // Handle case when no membership type is selected
      console.log("Please select a membership type.");
    }
  };
  
  return (
    <Box className="">
      <BackNav />
      <Box className="flex flex-col justify-center items-center gap-3 text-center px-4">
        <span className="md:text-2xl md:font-medium font-sm">
          <span className="Title text-bold">Explore</span> Our Membership
          Donation Page!
        </span>
        <span className="md:text-2xl md:font-medium font-sm">
          Your Contribution Not Only Unlocks Exclusive Benefits But Also
          Supports Our Cause.
        </span>
        <span className="md:text-2xl md:font-medium font-sm">
          Join Us In Making A Difference Today!
        </span>
      </Box>
      <Box className="flex flex-row justify-center items-center mt-10 px-4">
        <Box className="md:w-1/4 w-full h-8 flex flex-row justify-start items-center rounded-lg">
          <button
            onClick={() => sethandleClick(true)}
            className={`w-1/2 h-full ${
              handleClick
                ? "bg-Primary text-white"
                : "bg-[#f0f1f2] text-Primary"
            } flex font-semibold text-sm border border-Primary justify-center items-center rounded-s-lg`}
          >
            Monthly
          </button>
          <button
            onClick={() => sethandleClick(false)}
            className={`w-1/2 h-full ${
              handleClick ? "" : "bg-Primary text-white"
            } text-Primary text-sm font-semibold border border-Primary flex  justify-center items-center rounded-e-lg`}
          >
            Yearly
          </button>
        </Box>
      </Box>
      <Box
        fullWidth
        display="flex"
        justifyContent="space-evenly"
        gap={2}
        className="md:p-10 mt-16 px-4 h-full"
      >
        <Swiper
          modules={[Autoplay, Navigation, Pagination, Scrollbar, A11y]}
          spaceBetween={20}
          breakpoints={{
            640: {
              slidesPerView: 1,
            },
            768: {
              slidesPerView: 2,
            },
            1024: {
              slidesPerView: 4,
            },
          }}
          loop={true}
          pagination={{ clickable: true }}
          className=""
        >
          <SwiperSlide
            className={`md:w-1/4 w-full shadow-xl rounded-lg bg-white duration-150 ease-in-out`}
          >
            <button
              onClick={() => handleCardSelect("Bronze")}
              fontWeight="bold"
              className={`text-start p-5 w-full h-full hover:bg-Secondary flex items-start ${
                selectedMembershipType === "Bronze"
                  ? "bg-Secondary border-Secondary"
                  : ""
              }`}
            >
              <div>
                <span className="text-[2em] flex flex-col gap-3 justify-start items-center">
                  <img src={Bronze} alt="" className="w-24 top-4" />
                  Bronze
                </span>
                <br />
                <span className="text-[#a3a3a3] text-sm">
                  The Bronze package provides basic benefits suitable for
                  beginners, offering access to certificates and volunteering
                  opportunities.
                </span>
                <br />
                <span className="font-light">ETB</span>
                <span className="text-Primary text-4xl p-2 font-bold">100</span>
                <br />
                {handleClick ? (
                  <span className="text-[#a3a3a3] font-bold text-xs">
                    /Per Months
                  </span>
                ) : (
                  <span className="text-[#a3a3a3] font-bold text-xs">
                    /Per Year
                  </span>
                )}
                <br />
                <ul className="text-[#a3a3a3] font-semibold flex flex-col justify-start items-start text-s gap-5 mt-4">
                  <li className="flex gap-2 items-center">
                    <img src={pointer} alt="" className="w-6" />
                    Membership ID
                  </li>
                  <li className="flex gap-2 items-center">
                    <img src={pointer} alt="" className="w-6" />
                    Certificates
                  </li>
                  <li className="flex gap-2 items-center">
                    <img src={pointer} alt="" className="w-6" />
                    Volunteering Opportunities
                  </li>
                </ul>
              </div>
            </button>
          </SwiperSlide>

          <SwiperSlide className="md:w-1/4 w-full shadow-xl rounded-lg bg-white transform transition-transform duration-150 ease-in-out ">
            <button
              fontWeight="bold"
              onClick={() => handleCardSelect("Silver")}
              className={`text-start p-5 w-full h-full hover:bg-Secondary focus:bg-Secondary flex items-start ${
                selectedMembershipType === "Silver"
                  ? "bg-Secondary border-Secondary"
                  : ""
              }`}
            >
              <div className="">
                <span className="text-[2em] flex flex-col gap-3 justify-start items-center">
                  <img src={Silver} alt="" className="w-24 top-4" />
                  Silver
                </span>
                <br />
                <span className="text-[#a3a3a3] text-sm">
                  Comprehensive benefits, including screenings, education, and
                  volunteering. Ideal for all-round health and community
                  engagement.
                </span>
                <br />
                <span className="font-light">ETB</span>
                <span className="text-[#FF7FCE] text-4xl p-2 font-bold">
                  200
                </span>
                <br />
                {handleClick ? (
                  <span className="text-[#a3a3a3] font-bold text-xs">
                    /Per Months
                  </span>
                ) : (
                  <span className="text-[#a3a3a3] font-bold text-xs">
                    /Per Year
                  </span>
                )}
                <br />
                <ul className="text-[#a3a3a3] font-semibold flex flex-col justify-start items-start text-s gap-5 mt-4">
                  <li className="flex gap-2 items-center">
                    <img src={pointer} alt="" className="w-6" />
                    Membership ID
                  </li>
                  <li className="flex gap-2 items-center">
                    <img src={pointer} alt="" className="w-6" />
                    Certificates
                  </li>
                  <li className="flex gap-2 items-center">
                    <img src={pointer} alt="" className="w-6" />
                    Volunteering Opportunities
                  </li>
                  <li className="flex gap-2 items-center">
                    <img src={pointer} alt="" className="w-6" />
                    Training Opportunities
                  </li>
                  <li className="flex gap-2 items-center">
                    <img src={pointer} alt="" className="w-6" />
                    Screen One Family Member For Breast Cancer With An Exam And
                    Education.
                  </li>
                </ul>
              </div>
            </button>
          </SwiperSlide>

          <SwiperSlide className="md:w-1/4 w-full shadow-xl rounded-lg bg-white transform transition-transform hover:-translate-y-10 duration-150 ease-in-out ">
            <button
              onClick={() => handleCardSelect("Gold")}
              fontWeight="bold"
              className={`text-start p-5 w-full h-full hover:bg-Secondary flex items-start ${
                selectedMembershipType === "Gold"
                  ? "bg-Secondary border-Secondary"
                  : ""
              }`}
            >
              <div>
                <span className="text-[2em] font-semibold flex flex-col gap-3 justify-start items-center">
                  <img src={Gold} alt="" className="w-24 top-4" />
                  Gold
                </span>
                <br />
                <span className="text-[#a3a3a3] text-sm">
                  Gold package includes breast cancer screenings for up to 5
                  people/year and the option to invite a guest to the support
                  group yearly.
                </span>
                <br />
                <span className="font-light">ETB</span>
                <span className="text-[#FF7FCE] text-4xl p-2 font-bold">
                  500
                </span>
                <br />
                {handleClick ? (
                  <span className="text-[#a3a3a3] font-bold text-xs">
                    /Per Months
                  </span>
                ) : (
                  <span className="text-[#a3a3a3] font-bold text-xs">
                    /Per Year
                  </span>
                )}
                <br />
                <ul className="text-[#a3a3a3] font-semibold flex flex-col justify-start items-start text-s gap-5 mt-4">
                  <li className="flex gap-2 items-center">
                    <img src={pointer} alt="" className="w-6" />
                    Membership ID
                  </li>
                  <li className="flex gap-2 items-center">
                    <img src={pointer} alt="" className="w-6" />
                    Certificates
                  </li>
                  <li className="flex gap-2 items-center">
                    <img src={pointer} alt="" className="w-6" />
                    Volunteering Opportunities
                  </li>
                  <li className="flex gap-2 items-center">
                    <img src={pointer} alt="" className="w-6" />
                    Breast cancer screenings for up to 5 people/year.
                  </li>
                  <li className="flex gap-2 items-center">
                    <img src={pointer} alt="" className="w-6" />
                    Request 1 guest to join the support group/year
                  </li>
                </ul>
              </div>
            </button>
          </SwiperSlide>

          <SwiperSlide className="w-1/4 shadow-xl rounded-lg bg-white duration-150 ease-in-out">
            <button
              onClick={() => handleCardSelect("Platinum")}
              fontWeight="bold"
              className={`text-start p-5 w-full h-full hover:bg-Secondary flex items-start ${
                selectedMembershipType === "Platinum"
                  ? "bg-Secondary border-Secondary"
                  : ""
              }`}
            >
              <div>
                <span className="text-[2em] flex flex-col gap-3 justify-start items-center">
                  <img src={Platinum} alt="" className="w-24 top-4" />
                  Platinum
                </span>
                <br />
                <span className="text-[#a3a3a3] text-sm">
                  Platinum package includes free ultrasound and FNAC
                  investigations, in addition to its other benefits.
                </span>
                <br />
                <span className="font-light">ETB</span>
                <span className="text-[#FF7FCE] text-4xl p-2 font-bold">
                  1000+
                </span>
                <br />
                {handleClick ? (
                  <span className="text-[#a3a3a3] font-bold text-xs">
                    /Per Months
                  </span>
                ) : (
                  <span className="text-[#a3a3a3] font-bold text-xs">
                    /Per Year
                  </span>
                )}
                <br />
                <ul className="text-[#a3a3a3] font-semibold flex flex-col justify-start items-start text-s gap-5 mt-4">
                  <li className="flex gap-2 items-center">
                    <img src={pointer} alt="" className="w-6" />
                    Membership ID
                  </li>
                  <li className="flex gap-2 items-center">
                    <img src={pointer} alt="" className="w-6" />
                    Certificates
                  </li>
                  <li className="flex gap-2 items-center">
                    <img src={pointer} alt="" className="w-6" />
                    Volunteering Opportunities
                  </li>
                  <li className="flex gap-2 items-center">
                    <img src={pointer} alt="" className="w-6" />
                    Breast cancer screenings for up to 5 people/year.
                  </li>
                  <li className="flex gap-2 items-center">
                    <img src={pointer} alt="" className="w-6" />
                    Free investigation with US and FNAC
                  </li>
                </ul>
              </div>
            </button>
          </SwiperSlide>
        </Swiper>
      </Box>
      <Box className="flex justify-center items-center px-4">
        <Button
          onClick={handleNext}
          size="large"
          fontWeight="bold"
          fontSize="1.2em"
          display="flex"
          justifyContent="center"
          className="md:w-1/3 w-full hover:bg-black"
          align="center"
          sx={{
            backgroundColor: "#FF7FCE",
            color: "white",
            marginTop: "2%",
            marginBottom: "2%",
            "&:hover": {
              backgroundColor: "black",
            },
          }}
        >
          NEXT
        </Button>
      </Box>
      <Box
        display="flex"
        justifyContent="space-evenly"
        className="md:w-1/2 mx-auto px-4"
        sx={{ marginBottom: "4%", marginTop: "4%" }}
      >
        <Typography
          variant="h6"
          fontSize="1.4em"
          textAlign="center"
          fontWeight="bold"
          className="bg-[#FF7FCE] rounded-full p-1 md:w-12 w-14 md:h-12 h-11 text-white"
        >
          {" "}
          1{" "}
        </Typography>
        <hr className="w-[40%] h-[4%] my-auto" />
        <Typography
          variant="h6"
          fontSize="1.4em"
          textAlign="center"
          fontWeight="bold"
          align="center"
          className="bg-[#FF7FCE] rounded-full p-1 md:w-12 w-14 md:h-12 h-11 text-white"
        >
          {" "}
          2
        </Typography>
        <hr className="w-[40%] h-[4%] my-auto" />
        <Typography
          variant="h6"
          fontSize="1.4em"
          textAlign="center"
          fontWeight="bold"
          className="bg-gray-300 rounded-full p-1 md:w-12 w-14 md:h-12 h-11 text-white"
        >
          {" "}
          3{" "}
        </Typography>
      </Box>
    </Box>
  );
};

export default StepTwo;
