import React from "react";
import logo from "../../Assets/logo/Logo only.png";
import { Link } from "react-scroll";
import { NavLink } from "react-router-dom";
import Avatar1 from '../../Assets/avatar/boy_4140070.png'
import Avatar2 from '../../Assets/avatar/people_14083104.png'
import Avatar3 from '../../Assets/avatar/rapper_6407782.png'
import Avatar4 from '../../Assets/avatar/teacher_6369095.png'

const Navigation = ({ sticky, authenticated }) => {
  const nav = [
    { navigation: "HOME", link: "home" },
    { navigation: "WHAT WE DO", link: "whatwedo" },
    { navigation: "CANCER EDUCATION", link: "cancereducation" },
    { navigation: "NEWS FEED", link: "newsfeed" },
    { navigation: "ABOUT US", link: "aboutus" },
  ];

  const avatarImages = [Avatar1, Avatar2, Avatar3, Avatar4];

  const getRandomAvatar = () => {
    const randomIndex = Math.floor(Math.random() * avatarImages.length);
    return avatarImages[randomIndex];
  };
  return (
    <div
      className={
        !sticky
          ? `lg:flex flex-row justify-between items-center hidden px-10 py-2 text-sm w-full`
          : "fixed hidden w-full z-[11111] justify-between items-center py-3 bg-white"
      }
    >
      <div className="">
        <img src={logo} alt="" className="w-14" />
      </div>
      <div className="flex space-x-10">
        {nav.map((items, index) => (
          <div
            key={index}
            className="flex flex-row justify-center items-center"
          >
            <Link
              to={items.link}
              spy={true}
              smooth={true}
              offset={-100}
              duration={500}
              className="hover:underline hover:text-Primary cursor-pointer duration-100 ease-in-out"
            >
              {items.navigation}
            </Link>
          </div>
        ))}
      </div>
      <div className="flex justify-center items-center space-x-4">
        {authenticated ? (
          // Render avatar or profile component upon successful sign-in
        <div className="">
          <img src={getRandomAvatar()} alt="" className="w-10"/>
        </div>
        ) : (
          <>
            <NavLink
              to="/signup"
              className="bg-Primary px-4 py-2 font-bold text-white hover:bg-black duration-150 ease-in-out"
            >
              SIGN UP
            </NavLink>
            <NavLink
              to="/signin"
              className="hover:underline hover:text-Primary cursor-pointer duration-100 ease-in-out"
            >
              SIGN IN
            </NavLink>
          </>
        )}
      </div>
    </div>
  );
};

export default Navigation;
