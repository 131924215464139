import { useState } from "react";
import { BiError } from "react-icons/bi";
import Errors from "../Assets/icons/Error.png";

const Error = ({error, handleClose }) => {
  return (
    <div className="fixed bg-black bg-opacity-20 w-screen h-screen right-0 top-0 z-50 flex justify-center items-center">
      <div className="flex flex-col justify-center items-center p-4 bg-white rounded-lg shadow-xl gap-3">
        <img src={Errors} alt="" className="w-[7rem]" />
        <h1 className="flex flex-col jus items-center font-semibold ">
          There seems to be a problem. Please try again!
          <span className="text-red-600"></span>{" "}
        </h1>
        <button
          onClick={handleClose}
          className="w-full bg-Primary p-2 font-bold text-white uppercase text-xl rounded-lg hover:bg-black duration-150"
        >
          ok
        </button>
      </div>
    </div>
  );
};
export default Error;
